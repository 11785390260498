<template>
  <div id="app" class="d-flex flex-column h-100 justify-content-between">
    <navbar />
    <router-view/>
    <my-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  components: { Navbar, 'my-footer': Footer  },
  mounted() {
    console.log(`Base url:` + process.env.BASE_URL)
    
  },
  computed: {
    ...mapGetters([ 'selectedAccount' ])
  }
}
</script>