<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">

        <router-link
          to="/"
          v-slot="{ href, navigate, isExactActive }"
          custom
        >
          <a class="navbar-brand py-4" :href="href" @click="navigate">EtsyPrintOrder</a>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <router-link
              to="/"
              v-slot="{ href, navigate, isExactActive }"
              custom
            >
              <li class="nav-item">
                <a class="nav-link" :class="{ 'active': isExactActive }" aria-current="page" :href="href" @click="navigate">Home</a>
              </li>
            </router-link>

            <router-link
              to="/pricing"
              v-slot="{ href, navigate, isActive }"
              custom
            >
              <li class="nav-item">
                <a class="nav-link" :class="{ 'active': isActive }" :href="href" @click="navigate">Pricing</a>
              </li>

            </router-link>
            <router-link
              to="/support"
              v-slot="{ href, navigate, isActive }"
              custom
            >
              <li class="nav-item">
                <a class="nav-link" :class="{ 'active': isActive }" :href="href" @click="navigate">Support</a>
              </li>
            </router-link>

            <router-link
              to="/settings"
              v-slot="{ href, navigate, isActive }"
              custom
            >
              <li class="nav-item">
                <a class="nav-link" :class="{ 'active': isActive }" :href="href" @click="navigate">Settings</a>
              </li>
            </router-link>
          </ul>

          <div class="mx-auto dropdown" v-if="selectedAccount && selectedAccount.etsyData">
            <div class=" user-dropdown"  role="button" data-bs-toggle="dropdown" aria-expanded="false" id="navbarDropdown">
              <div class="d-flex justify-content-center">
                <div class="my-auto user-name">{{ selectedAccount.etsyData.shop_name }}</div>
                <div class="avatar-container">
                  <img class="avatar img img-circle" :src="selectedAccount.etsyData.icon_url_fullxfull ||'https://www.etsy.com/images/avatars/default_shop_icon_280x280.png'" />
                </div>
              </div>
            </div>
            <ul class="dropdown-menu ms-auto" aria-labelledby="navbarDropdown" style="margin-top: 15px; border-top-right-radius: 0px; border-top-left-radius: 0px; border-top: 0;">
              <li 
                v-for="account of accounts.filter(x => x.id != selectedAccount.id)" 
                v-bind:key="account.id">
                <a class="dropdown-item" href="#" @click="switchTo(account.id)" v-if="account && account.etsyData">
                  <img 
                    style="height: 60px; width: 60px; border-radius: 50%; border: 3px solid black;margin-right: 10px"
                    :src="account.etsyData.icon_url_fullxfull ||'https://www.etsy.com/images/avatars/default_shop_icon_280x280.png'" />
                  {{ account.etsyData.shop_name }}
                </a>
              </li>
              <hr>
              <li><a class="dropdown-item" href="#" @click="auth">Add another account</a></li>
              <hr>
              <li><a class="dropdown-item" href="#" @click="logout">Logout</a></li>
            </ul>
          </div>

          <ul v-else class="navbar-nav ms-auto mb-2 mb-lg-0">
            <router-link
              to="/settings"
              v-slot="{ href, navigate, isActive }"
              custom
            >
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" :href="href" @click="navigate">Login</a>
              </li>
            </router-link>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
export default {

  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/?action=logout');
    },
    auth() {
      window.location.href = `${process.env.VUE_APP_SERVER_BASE_URL}/EtsySignIn?redirect=${process.env.VUE_APP_UI_BASE_URL}/settings`
    },
    switchTo(id) {
      this.$store.dispatch('selectAccount', id);
      this.$forceUpdate();
      // this.$router.push('/settings');
    }
  },
  computed: {
    ...mapGetters(['selectedAccount', 'accounts'])
  }
};
</script>

<style>
</style>