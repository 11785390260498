import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import NProgress from 'vue-nprogress' // https://www.npmjs.com/package/vue-nprogress
Vue.use(NProgress, { latencyThreshold: 0 })
const nprogress = new NProgress();

import '@/scss/custom.scss'
import '@popperjs/core'
import 'bootstrap/dist/js/bootstrap'

Vue.config.productionTip = false

new Vue({
  nprogress,
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
