import axios from "axios";

const instance = axios.create();

export function stripeSetupSubscripion() {
    return instance.post(`${process.env.VUE_APP_SERVER_BASE_URL}/CreateStripeCheckout`,  {
        successRedirect: process.env.VUE_APP_UI_BASE_URL + '/payment-success',
        cancelRedirect: process.env.VUE_APP_UI_BASE_URL + '/payment-failure',
    });
}

export function stripeManageSubscription() {
    return instance.post(`${process.env.VUE_APP_SERVER_BASE_URL}/CreateStripeManagePortal`,  {
        returnUrl: process.env.VUE_APP_UI_BASE_URL,
    });
}

export function getShop() {
    return instance.get(`${process.env.VUE_APP_SERVER_BASE_URL}/GetShop`);
}

export function updateShop(shop) {
    return instance.post(`${process.env.VUE_APP_SERVER_BASE_URL}/UpdateShop`, shop);
}

export default instance;