import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios, { getShop, updateShop } from '../api';

const vuexLocal = new VuexPersistence({
  key: 'vuex-eop',
  storage: window.localStorage
});

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    accounts: [],
    selectedAccount: null,
    tokenMapping: {}
  },
  getters: {
    accounts: state => state.accounts,
    selectedAccount: state => state.selectedAccount,
  },
  mutations: {
    ADD_ACCOUNT: (state, account) => {
      state.accounts.push(account);
      state.selectedAccount = account;
    },
    UPDATE_ACCOUNT: (state, { token, account }) => {
      let accountIndex

      if (account.id) {
        accountIndex = state.accounts.findIndex(x => x.id == account.id);
      }
      if (accountIndex == -1 && token) {
        accountIndex = state.accounts.findIndex(x => x.token == token);
      }
      
      account.token = state.accounts[accountIndex].token;
      state.tokenMapping[account.id] = state.tokenMapping[account.id] || token;

      state.selectedAccount = account;
      Vue.set(state.accounts, accountIndex, account)
    },
    LOGOUT: (state) => {
      state.accounts.splice(state.accounts.findIndex(x => x.token == state.selectedAccount.token), 1);
      state.selectedAccount = state.accounts[0];
    },
    SET_ACCOUNT: (state, accountId) => {
      state.selectedAccount = state.accounts.find(x => x.id == accountId);
    }
  },
  actions: {
    async authAccount(context, { token, shopId, }) {
      // We have a token, lets get their shop data via the API.
      axios.defaults.headers["authorization"] = token;
      let shopResponse = await getShop();
      context.commit("ADD_ACCOUNT", { token, shopId });
      context.commit("UPDATE_ACCOUNT", { token, account: shopResponse.data.shopData });
    },
    selectAccount(context, accountId) {
      context.commit("SET_ACCOUNT", accountId);
      axios.defaults.headers["authorization"] = context.state.tokenMapping[accountId];
    },
    async refreshShop(context) {
      let shopResponse = await getShop();
      context.commit("UPDATE_ACCOUNT", { account: shopResponse.data.shopData });
    },
    async updateAccount(context, newDetails) {
      console.log("Updating shop");
      let account = (await updateShop(newDetails)).data;
      context.commit("UPDATE_ACCOUNT", { account });
    },
    logout(context) {
      context.commit("LOGOUT");
    }
  },
  modules: {

  },
  plugins: [ vuexLocal.plugin ]
});

export default store

if (store.getters.selectedAccount) axios.defaults.headers["authorization"] = store.getters.selectedAccount.token;