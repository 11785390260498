import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { routes } from "./routes";
import Swal from "sweetalert2";

Vue.use(VueRouter);

// Dynamic ones that require more code (Tried to use a sitemap generator.)
routes.push(...[
  {
      path: "/payment-success",
      beforeEnter: (to, from, next) => {
          // Tell teh store?
          console.log("Got a payment success!");
          router.push("/");
      },
  },
  {
      path: "/payment-failure",
      beforeEnter: (to, from, next) => {
          // Tell teh store?
          console.log("Got a payment failure!");
          router.push("/");
      },
  },
]);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async function(to, from, next) {
  console.log("Checking auth")
  if (to.query.noShop) {
    await Swal.fire({ title: "No shop", icon: "error", text: "Hi, this app is only for Etsy's sellers. To use this app, please create an Etsy shop first."});
    router.push('/');
  }

  if (to.query.token) {
    // They have logged in and have a valid JWT with us.
    // Are they already logged in?
    if (store.getters.accounts.find(x => x.id == to.query.shopId)) {
      delete to.query.token;
      delete to.query.shopId;
      return router.replace({ query: to.query });
    }

    await store.dispatch('authAccount', {
      token: to.query.token,
      shopId: to.query.shopId,
    });
    
    delete to.query.token;
    delete to.query.shopId;
    router.push({ path: '/settings', query: to.query });
  }

  // Check we are logged in, everything requires authentication.
  if (to.meta.auth) {
    if (store.getters['accounts'].length == 0) {
      // Navigate them to sign in.
      // this.router.push()
      window.location.href = `${process.env.VUE_APP_SERVER_BASE_URL}/EtsySignIn?redirect=${process.env.VUE_APP_UI_BASE_URL}`
    }
  }

  // TODO: Select account? 
  
  return next();
});

export default router
// 