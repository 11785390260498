export const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    {
        path: "/terms",
        name: "Terms",
        component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    },
    {
        path: "/pricing",
        name: "Pricing",
        component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing.vue'),
    },
    {
        path: "/support",
        name: "Support",
        component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue'),
    },
    {
        path: "/settings",
        name: "Settings",
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
        meta: {
            auth: true,
        },
    },
];
